<template>
	<div class="pc-main">
		<template v-if="tool || show_search">
			<div style="padding: 20px 20px 0; margin: -20px -20px 0; background-color: #f9f9f9;" v-if="show_search">
				<slot name="header"></slot>
			</div>
		</template>
		<div class="main-table">
			<canvas id="watermarkCanvas" class="watermark-canvas"></canvas>
			<slot name="table"></slot>
			<div :class="['search', {show: show_search}]" @click="show_search = !show_search" v-if="!tool">
				<i class="el-icon-search"></i> {{show_search ? '收起' : '过滤'}}
			</div>
		</div>
		<div style="display: flex; align-items: center; justify-content: space-between;">
			<slot name="footer"></slot>
			<div style="width: 100px; color: #999; font-size: 14px;">管理系统 v1.0.0</div>
		</div>
	</div>
</template>

<style>
.watermark-container {
  position: relative;
}

.watermark-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; /* 禁止Canvas响应鼠标事件 */
}

.content {
  /* 这里可以添加页面内容的样式 */
}
</style>


<script>
	export default {
		props: {
			tool: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				show_search: false
			}
		},
		mounted () {
			// this.generateWatermark();
		},
		methods: {
			generateWatermark() {
				const canvas = document.getElementById('watermarkCanvas');
				const ctx = canvas.getContext('2d');

				// 设置Canvas大小
				canvas.width = 300; // 设置Canvas宽度
				canvas.height = 150; // 设置Canvas高度

				// 绘制水印文字
				ctx.font = '24px Arial'; // 设置字体大小和样式
				ctx.fillStyle = 'rgb(100, 100, 100)'; // 设置文字颜色和透明度
				// ctx.rotate(-30 * Math.PI / 180); // 旋转角度

				// 绘制多行水印文字
				ctx.fillText('Watermark', 0, 0);
				ctx.fillText('Sample', 100, 50);
				ctx.fillText('Text', 200, 100);
			}
		}
	}
</script>